import axios from 'axios';
import authHeader from './setAuthHeader';
let API_URL = process.env.VUE_APP_ROOT_API

class changepasswordApi {

  post(data){
    return axios.post(API_URL + 'change-password',data,{ headers: authHeader() });
  }
  
  Passaword(data){
    data['_method'] = 'PUT';
    return axios.post(API_URL + 'student/change-password', data, { headers: authHeader() });
  }

  }
  export default new changepasswordApi();