import { createWebHistory, createRouter } from "vue-router";
import auth  from "./middleware/auth";
import admin from "./middleware/admin";
import student from "./middleware/student";
import middlewarePipeline from "./middleware/middleware-pipeline";
const routes =  [
  // {
  //   path: '/',
  //   redirect: '/admin/dashboard'
  // },
  {
    path: "/",
    name: "Layouts",
    component: () => import("../src/LandingPage/Layouts.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../src/LandingPage/Contact.vue")
  },
  {
    path: "/students-placemets",
    name: "PlacementPage",
    component: () => import("../src/LandingPage/PlacementPage.vue")
  },
  {
    path: "/about-us",
    name: "AboutUsPage",
    component: () => import("../src/LandingPage/AboutUsPage.vue")
  },
   {
    path: "/courses/:id",
    name: "Courses",
    component: () => import("../src/LandingPage/Courses.vue")
   },

  {
    path: "/enquiry",
    name: "Enquiry",
    component: () => import("../src/LandingPage/Enquiry.vue")
  },

  {
    path: "/admin/dashboard",
    name: "Dashbord",
    meta: {
      middleware: [
        auth,
        admin,
        student
       ],
       title:'Dashboard'
       },
    component: () => import("./components/Dashboard/Index.vue")
  },
  // -----
  {
    path: "/student/dashboard",
    name: "student-dashboard",
    meta: {
      middleware: [
        auth
       ],
       title:'Dashboard'
       },
    component: () => import("./components/StudentView/Dashboard/index.vue")
  },

  {
      path: "/admin/students",
      name: "StudentList",
      meta: {
        middleware: [
          auth,
          admin
         ],
       title:'Students'

         },
      component: () => import("../src/components/Students/List.vue")
    },
    {
      path: "/students/create",
      name: "StudentCreate",
      meta: {
        middleware: [
          auth,
          admin
         ],
         title:'New Student' },
      component: () => import("../src/components/Students/Create.vue")
    },

    {
      path: "/students/edit/:id",
      name: "StudentEdit",
      meta: {
        middleware: [
          auth
         ],
         title: 'Update Student'
         },
      component: () => import("../src/components/Students/Edit.vue")
    },

    {
      path: "/students/view/:id",
      name: "StudentView",
      meta: {
        middleware: [
          auth
         ],
         title: 'View Student'
         },
      component: () => import("../src/components/Students/View.vue")
    },
    {
      path: "/admin/todos",
      name: "TodoList",
      meta: {
        middleware: [
          auth
         ],
       title:'Todos'

         },
      component: () => import("../src/components/Todo/Index.vue")
    },
    {
      path: "/admin/todos/view/:id",
      name: "TodoView",
      meta: {
        middleware: [
          auth,
          admin
         ]
         },
      component: () => import("../src/components/Todo/View.vue")
    },

    {
      path: "/teachers/create",
      name: "TeachersCreate",
      meta: {
        middleware: [
          auth,
          admin
         ],
         },
      component: () => import("../src/components/Teachers/Create.vue")
    },

    {
      path: "/teachers/edit/:id",
      name: "TeachersEdit",
      meta: {
        middleware: [
          auth,
          admin
         ]
         },
      component: () => import("../src/components/Teachers/Edit.vue")
    },
    {
      path: "/admin/teachers",
      name: "TeachersList",
      meta: {
        middleware: [
          auth,
          admin
         ],
       title:'Teachers'
         },
      component: () => import("../src/components/Teachers/List.vue")
    },

    {
      path: "/courses/create",
      name: "CoursesCreate",
      meta: {
        middleware: [
          auth,
          admin
         ]
         },
      component: () => import("../src/components/Courses/Create.vue")
    },

    {
      path: "/courses/edit/:id",
      name: "CoursesEdit",
      meta: {
        middleware: [
          auth,
          admin
         ]
         },
      component: () => import("../src/components/Courses/Edit.vue")
    },
    {
      path: "/admin/courses",
      name: "CoursesList",
      meta: {
        middleware: [
          auth,
          admin
         ],
       title:'Courses'
         },
      component: () => import("../src/components/Courses/List.vue")
    },
    // ---contactUs page links

    {
      path: "/admin/contact-us",
      name: "ContactIndex",
      meta: {
        middleware: [
          auth,
          admin
         ],
       title:'Contact'
         },
      component: () => import("../src/components/ContectUs/Index.vue")
    },


    {
      path: "/admin/users",
      name: "UsersList",
      meta: {
        middleware: [
          auth,
          admin
         ],
       title:'Users'
         },
      component: () => import("../src/components/Users/List.vue")
    },
    {
      path: "/placement/create",
      name: "PlacementCreate",
      meta: {
        middleware: [
          auth,
          admin
         ],
       title:'PlacementCreate'
         },
      component: () => import("../src/components/Placement/Create.vue")
    },
   //placements module start
  //  {
  //   path: "/placement/create",
  //   name: "PlacementCreate",
  //   meta: {
  //     middleware: [
  //       auth,
  //       admin
  //     ]
  //   },
  //   component: () => import("../src/components/Placement/Create.vue")
  // },
    {
      path: "/admin/placement",
      name: "Placement",
      meta: {
        middleware: [
          auth,
          admin
         ],
       title:'Placement'
         },
      component: () => import("../src/components/Placement/List.vue")
    },

    {
      path: "/user/create",
      name: "UsersCreate",
      meta: {
        middleware: [
          auth,
          admin
         ]
         },
      component: () => import("../src/components/Users/Create.vue")
    },
    {
      path: "/user/edit/:id",
      name: "UsersEdit",
      meta: {
        middleware: [
          auth,
          admin
         ]
         },
      component: () => import("../src/components/Users/Edit.vue")
    },

    {
      path: "/admin/roles",
      name: "RolepermissionList",
      meta: {
        middleware: [
          auth,
          admin
         ],
       title:'Role Permissions'
         },
      component: () => import("../src/components/Rolepermission/List.vue")
    },

    {
      path: "/role/create",
      name: "RolepermissionCreate",
      meta: {
        middleware: [
          auth,
          admin
         ],
         title:'New Role'
         },
      component: () => import("../src/components/Rolepermission/Create.vue")
    },
    {
      path: "/enquiry/create",
      name: "StudentEnquiry",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title:'New Inquirie'
      },
      component: () => import("../src/components/Enquiry/Create.vue")
    },
    {
      path: "/enquiry/edit/:id",
      name: "EnquiryEdit",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title: 'Update Inquiry'
      },
      component: () => import("../src/components/Enquiry/Edit.vue")
    },
    {
      path: "/admin/inquirys",
      name: "EnquiryList",
      meta: {
        middleware: [
          auth,
          admin
        ],
       title:'Inquiries'
      },
      component: () => import("./components/Enquiry/List.vue")
    },

    {
      path: "/packages/create",
      name: "PackagesEnquiry",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("../src/components/Packages/Create.vue")
    },
    {
      path: "/packages/edit/:id",
      name: "PackagesEdit",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("../src/components/Packages/Edit.vue")
    },
    {
      path: "/admin/packages",
      name: "PackagesList",
      meta: {
        middleware: [
          auth,
          admin
        ],
       title:'Packages'
      },
      component: () => import("./components/Packages/List.vue")
    },

    {
      path: "/payment/create",
      name: "PaymentCreate",
      meta: {
        middleware: [
          auth,
          admin
        ],
      },
      component: () => import("../src/components/Payment/Create.vue")
    },
    {
      path: "/admin/payments",
      name: "PaymentList",
      meta: {
        middleware: [
          auth,
          admin
        ],
       title:'Payments'
      },
      component: () => import("./components/Payment/List.vue")
    },

    {
      path: "/Attendance/create",
      name: "AttendanceCreate",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("../src/components/Attendance/Create.vue")
    },
    {
      path: "/Attendance/edit/:id",
      name: "AttendanceEdit",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("../src/components/Attendance/Edit.vue")
    },
    {
      path: "/admin/attendances",
      name: "AttendanceList",
      meta: {
        middleware: [
          auth,
          admin
        ],
       title:'Attendances'
      },
      component: () => import("../src/components/Attendance/List.vue")
    },

    {
      path: "/addpackages/create",
      name: "AddPackages",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("../src/components/StudentsPackage/Create.vue")
    },
    {
      path: "/addpackages/edit/:id",
      name: "AddPackagesEdit",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("../src/components/StudentsPackage/Edit.vue")
    },
    {
      path: "/admin/student-packages",
      name: "AddPackagesList",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title:'Student Packages'
      },
      component: () => import("./components/StudentsPackage/List.vue")
    },

     // -----------Todos start --------

     {
      path: '/student/todos',
      name:'StudentTodo',
      meta:{
        middleware:[
          auth
        ],
        title:'Todos'
      },
      component: () => import ('./components/StudentView/Todos/List.vue')

    },

    {
      path: '/student/todos/create',
      name:'StudentTodo-create',
      meta:{
        middleware:[
          auth
        ]
      },
      component: () => import ('./components/StudentView/Todos/Create.vue')

    },
    {
      path: '/student/todos/edit',
      name:'StudentTodo-edit',
      meta:{
        middleware:[
          auth
        ]
      },
      component: () => import ('./components/StudentView/Todos/Edit.vue')

    },
  // -----------Todos end --------

    {
      path: "/admin/task-courses",
      name: "TaskCourses",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title:'Task Courses'
      },
      component: () => import("./components/TaskCourses/List.vue")
    },
    {
      path: "/taskscourses/Edit/:id",
      name: "TasksCourses",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("./components/TaskCourses/Edit.vue")
    },
    {
      path: "/task/create",
      name: "TaskCreate",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("../src/components/Task/Create.vue")
    },
    {
      path: "/task/edit/:id",
      name: "TaskEdit",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("../src/components/Task/Edit.vue")
    },
    {
      path: "/admin/tasks",
      name: "TaskList",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title:'Tasks'
      },
      component: () => import("./components/Task/List.vue")
    },
// --------------Holiday start------------
{
  path: "/admin/holidays",
  name: "Holiday",
  meta: {
    middleware: [
      auth,
      admin
    ],
    title:'Holidays'
  },
  component: () => import("./components/Holiday/List.vue")
},

{
  path: "/holiday/edit/:id",
  name: "HolidayEdit",
  meta: {
    middleware: [
      auth,
      admin
     ]
     },
  component: () => import("../src/components/Holiday/Edit.vue")
},
// --------------Holiday start------------
    {
      path: "/notification/create",
      name: "NotificationCreate",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("../src/components/Notification/Create.vue")
    },
    {
      path: "/notification/edit/:id",
      name: "NotificationEdit",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("../src/components/Notification/Edit.vue")
    },
    {
      path: "/admin/notifications",
      name: "NotificationList",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title:'Notifications'
      },
      component: () => import("./components/Notification/List.vue")
    },

    {
      path: "/student/today-task",
      name: "TaskListtt",
      meta: {
        middleware: [
          auth,
        ],
        title:'Today Task'
      },
      component: () => import("./components/StudentView/CurrentTask/List.vue")
    },
    {
      path: "/student/complete-tasks",
      name: "ComplateTask",
      meta: {
        middleware: [
          auth
        ],
        title:'Complated Tasks'
      },
      component: () => import("./components/StudentView/CompleteTask/List.vue")
    },

    {
      path: "/student/attendances",
      name: "StudentAttendance",
      meta: {
        middleware: [
          auth
        ],
        title:'Attendances'
      },
      component: () => import("./components/StudentView/AttendanceList/List.vue")
    },
    {
      path: "/studentLeave/create",
      name: "studentleave-create",
      meta: {
        middleware: [
          auth
        ]
      },
      component: () => import("./components/StudentView/StudentLeave/Create.vue")
    },

    {
      path: "/student/completed-courses",
      name: "studentCopetedCourse-List",
      meta: {
        middleware: [
          auth
        ],
        title:'Complete Courses'
      },
      component: () => import("./components/StudentView/Completecourse/List.vue")
    },

    {
      path: "/student/leaves",
      name: "StudentLeave",
      meta: {
        middleware: [
          auth
        ],
        title:'Leaves'
      },
      component: () => import("./components/StudentView/StudentLeave/List.vue")
    },
    // ---------------------------holiday start----------------

    {
      path: "/student/holidays",
      name: "Holidays",
      meta: {
        middleware: [
          auth
        ],
        title:'Holidays'
      },
      component: () => import("./components/StudentView/Holiday/Index.vue")
    },
    // ---------------------------holiday end----------------
    {
      path: '/admin/login',
      name: 'login',
      component: () =>
        import('../src/Auth/login.vue'),
    },

    {
      path: '/profile',
      name: 'profile',
      component: () =>
        import('../src/Auth/profile.vue'),
    },
    // {
    //   path: '/studentprofile',
    //   name: 'Studentprofile',
    //   component: () =>
    //     import('../src/Auth/Studentrofile.vue'),
    // },
    {
      path: '/changepassword',
      name: 'changepassword',
      component: () =>
        import('../src/Auth/changepassword.vue'),
    },

    {
      path: '/register',
      name: 'register',
      component: () =>
        import('../src/Auth/register.vue'),
    },
    {
      path: '/student/login',
      name: 'Studentlogin',
      component: () =>
        import('../src/Auth/Studentslogin.vue'),
    },

    {
      path: "/admin/complete-tasks",
      name: "Completed Task",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title:'Completed Tasks'
      },
      component: () => import("./components/Complete Task/List.vue")
    },

    {
      path: "/admin/leaves",
      name: "Leave list",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title:'Leaves'
      },
      component: () => import("./components/Leave/List.vue")
    },
    {
      path: "/leave/create",
      name: "Leave",
      meta: {
        middleware: [
          auth,
          admin
        ]
      },
      component: () => import("./components/Leave/Create.vue")
    },
    {
      path: "/:catchAll(.*)*",
      name: "NotFound",

      component: () => import("./components/Fallback/NotFound.vue")
    },

    // ---------setting module start--------
    {
      path: "/admin/settings",
      name: "setting",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title:'Settings'
      },
      component: () => import("./components/Setting/Index.vue")
    },

    {
      path: "/admin/settings-menu",
      name: "setting menu",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title:'Truncate'
      },
      component: () => import("./components/SettingMenu/index.vue")
    },
    // ---------setting module end--------


    {
      path: "/admin/company",
      name: "Company",
      meta: {
        middleware: [
          auth,
          admin
        ],
        title:'Companies'
      },
      component: () => import("../src/components/Company/List.vue")
    },
    {
      path: "/admin/profile",
      name: "Profile",
      meta: {
        middleware: [
          auth,
          admin,
          student
         ],
         title:'Profile'
         },
      component: () => import("../src/components/Profile/Index.vue")
    },
    {
      path: "/student/profile",
      name: "StudentProfile",
      meta: {
        middleware: [
          auth,
         ],
         title:'Student Profile'
         },
      component: () => import("../src/components/StudentView/StudentProfile/Index.vue")
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({behavior:'smooth'});
 }
});

router.beforeEach((to, from, next) => {

  /** Navigate to next if middleware is not applied */
  if (!to.meta.middleware) {
      return next()

  }

  const middleware = to.meta.middleware;
  const context = {

    to,
    from,
    next,
    //   store  | You can also pass store as an argument
  }

  return middleware[0]({
      ...context,
      next:middlewarePipeline(context, middleware,1)
  })
})

export default router;
