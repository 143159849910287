<template>
<!-- <div v-if="isOpen"> -->
<section class="z-20 h-screen w-screen bg-gray-800 fixed top-0 opacity-50" @click="$emit('close-modal')"></section>
<div class="absolute inset-0">
    <div class="flex h-[30%]  mt-[6rem]">
        <div class="z-30 m-auto bg-white p-2 shadow md:w-1/3 w-4/5  modal-content border-none">
            <div class="px-4 py-3">
                <h1 class="text-2xl text-start font-medium mb-2 border-b-2 border-gray-100 py-2 font-mono">Student Profile</h1>

                <form @click="prevent" class="mt-3">
                    <div class="grid grid-cols-1 gap-5">

                        <div>
                            <label class="font-semibold">Name</label>
                            <input v-model="name" class="rounded w-full p-2 shadow-md  focus:ring-blue-500 focus:border-blue-500" type="text" placeholder="Name">
                        </div>
                        <div>
                            <label class="font-semibold">Email</label>
                            <input v-model="email" class="rounded w-full p-2 shadow-md focus:ring-blue-500 focus:border-blue-500" disabled type="email" placeholder="Email">
                        </div>
    
                        <div>
                            <label class="font-semibold">files</label>
                            <input class="bg-gray-50 shadow-md  rounded py-1 px-3 block focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full" type="file" @change="addFile" />
    
                        </div>
                        <div id="preview" class="rounded-full">
                            <img class="w-20 h-24 mt-3" v-if="url" :src="url" />
                        </div>
                    </div>
                    <div class=" flex justify-end space-x-4 mt-3">
                        <button type="button" class="px-4 py-2  shadow-md bg-indigo-50  font-semibold rounded-md" @click="updateProfile">Save</button>
                        <button @click="$emit('close-modal')" type="button" class="rounded-md px-4 py-2 font-semibold bg-gray-50  shadow-md  text-black">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->
</template>

    
    
<script>
import profileApi from '@/utils/profileApi';

export default {
    props: {
        showmodal: Boolean,
    },
    data() {
        return {
            name: '',
            email: '',
            files: '',
            url: null
        }
    },
    methods: {
        async updateProfile() {
            let params = {
                file: this.files
            }
            profileApi.studentProfileUpdate(params)
                .then((res) => {
                    console.log(res.data.data, 'thisidfsdhi');
                    // localStorage.setItem("user", JSON.stringify(res.data.data));
                }).catch(error => {
                    console.log(error);
                }).then(() => {
                    this.$emit('close-modal')
                })

        },
        addFile: function (evt) {
            const file = evt.target.files[0]

            this.url = URL.createObjectURL(file);

            this.files = file

        }

    },
    created() {
        this.name = JSON.parse(localStorage.getItem('user')).name;
        this.email = JSON.parse(localStorage.getItem('user')).email;
        this.url = JSON.parse(localStorage.getItem('user')).file;
    }
}
</script>
    
<style>
      .modal-content {
        animation-name: animatetop;
        animation-duration:1000ms
      }

      @keyframes animatetop {
        from {top:-300px; opacity:0}
        to {top:0; opacity:1}
      }
</style>
