import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import DKToast from 'vue-dk-toast';
import 'izitoast/dist/css/iziToast.min.css'
import VueApexCharts from "vue3-apexcharts";
// import vSelect from 'vue-select'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ToastPlugin from 'vue-toast-notification';
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { VueEditor } from "vue3-editor";
import VueRouter from 'vue-router';


// loadFonts()
const app = createApp(App)
app.use(VueApexCharts);
app.use(router);
app.use(VueRouter);
app.use(VueEditor);
app.use(store);
app.use(VueSweetalert2);
app.use('VueDatePicker', VueDatePicker)
app.use(DKToast, {
    duration: 1500,
    positionY: 'top', // 'top' or 'bottom'
    positionX: 'right', // 'right' or 'left'
    styles: {
        color: 'white',
        backgroundColor: 'red',
        width : '50%',
    },
});
app.use(ToastPlugin);

app.config.globalProperties.filters ={
    displaySuccessMessage (msg) {
        const $toast = useToast();
        $toast.open({
            message: msg,
            type: 'success',
            position: 'top-right'
        });
    },
    displayErrorMessage (msg) {
        const $toast = useToast();
        $toast.open({
            message: msg,
            type: 'error',
            position: 'top-right'
        });
    },
}
app.mount('#app')

