export default function admin({next,store}){

    let user = localStorage.getItem("user");

    if (user) {
      user = JSON.parse(user);

      let student = user.type === 'student'
      if (student) {
        return next({
          name: 'student-dashboard'
        })
      }
      return next();
    }
    return next({
      name: 'login'
    })
  
  }
  