import { createStore } from 'vuex';
import _ from 'lodash';

export default createStore({
    state: {
        user: '',
        permissions: [],
    },
    mutations: {
        loginUser(state, user) {
            state.user = user;
            localStorage.setItem("token", user.token);
            localStorage.setItem("user", JSON.stringify(user));
        },
         loginUser(state, user) {
            state.user = user;
            localStorage.setItem("token", user.token);
            localStorage.setItem("user", JSON.stringify(user));
        },
        logout(state) {
            state.user = "";
            state.permissions = [];
            localStorage.clear();
        },
        initialiseStore(state) {
            if (localStorage.getItem('user')) {
                let user = JSON.parse(localStorage.getItem('user'));
                let permissions = user.role?.permission;
                state.user = user;
                state.permissions = permissions ? _.map(permissions, 'name') : [];
            }
        },
        setPermissions(state, permissions) {
            if (permissions) {
                state.permissions = _.map(permissions, 'name')
            }
        }
    },
    actions: {

    }

})