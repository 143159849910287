export default function auth({next,store}){
    // let isLoggedIn = false // Can be calculated through store
    // let isLoggedIn = store.getters['sessionData/isLoggedIn']
    let isLoggedIn = localStorage.getItem("token");


    if(isLoggedIn == null){
        return next({
            name: 'login'
        })
    }
    return next();
 }