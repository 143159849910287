<template>
<!-- <div v-if="isOpen"> -->
<section class=" right-0 z-20 h-screen w-screen bg-gray-500 fixed top-0 opacity-50" @click="$emit('close-modal')"></section>
<div class="absolute inset-0">
    <div class="flex h-full mt-[3.75rem]">
        <div class="z-30 m-auto bg-white  rounded p-2 shadow md:w-1/3 w-4/5 modal-content">
            <div class="p-3">
                <h1 class="text-2xl text-center font-medium">Profile</h1>

                <form @click="prevent" class="">
                    <div class="my-1">
                        <label>Name</label>
                        <input v-model="name" class="rounded w-full p-2 border-2 border-blue-200 focus:ring-blue-500 focus:border-blue-500" type="text" placeholder="Name">
                    </div>
                    <div class="mb-3">
                        <label>Email</label>
                        <input v-model="email" class="rounded w-full p-2 border-2 border-blue-200 focus:ring-blue-500 focus:border-blue-500"  type="email" placeholder="Email">
                    </div>

              <div>
                 <label class="text-sm text-gray-700 block mb-1 font-medium">files</label>
                 <input class="bg-gray-100 border-2 border-blue-200 rounded py-1 px-3 block focus:ring-blue-500 focus:border-blue-500 text-gray-700 w-full" type="file" @handleUpload="handleUpload" @change="addFile" />

              </div>

            <div id="preview" class="w-20 h-20 rounded-full">
            <img v-if="url || image"  :src="url || image"  />
           </div>
                    <div class=" flex justify-end space-x-4">
                        <button type="button" class="px-4 py-2 bg-indigo-500 hover:bg-indigo-600 shadow-lg shadow-indigo-500/50 text-white rounded-md" @click.prevent="updateProfile">Save</button>
                        <button @click="$emit('close-modal')" type="button" class=" rounded-md px-4 py-2 bg-gray-300 hover:bg-gray-400 shadow-lg shadow-gray-300 text-black">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->
</template>

<script>
import profileApi from '@/utils/profileApi';

export default {
    props: {
        showmodal: Boolean,
    },
    data() {
        return {
            name: '',
            email: '',
            image:'',
            files:'',
            url:null
        }
    },
    methods: {
        handleUpload(file){
            this.image = file;
        },

        async updateProfile() {
            let params = {
                name: this.name,
                email: this.email,
                file:this.files
            }
            profileApi.update(params)
                .then((res) => {
                    localStorage.setItem("user", JSON.stringify(res.data.data));
                }).catch(error => {
                    console.log(error);
                })

                this.$emit('close-modal');
        },
        addFile: function (event) {
            const file = event.target.files[0]
            this.image = URL.createObjectURL(file);
            this.files = file

        }

    },
    created() {
        this.name = JSON.parse(localStorage.getItem('user')).name;
        this.email = JSON.parse(localStorage.getItem('user')).email;
        this.image = JSON.parse(localStorage.getItem('user')).file;
    }
}
</script>

<style>
      .modal-content {
  animation-name: animatetop;
  animation-duration:1000ms
}

/* Add Animation */
@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}
</style>
