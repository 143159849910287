import axios from 'axios';
import authHeader from './setAuthHeader';
let API_URL = process.env.VUE_APP_ROOT_API

class SettingApi{

    create(data) {
        return axios.post(API_URL + 'settings/store', data, { headers: authHeader() }); }
         updated(id,data) { return axios.post(API_URL + `settings/${id}` + "/update",data, { headers: authHeader() });
      }
      // shows(id) {
      //   return axios.get(API_URL + `settings/${id}` + "/show", { headers: authHeader() });
      // }
      index() {
        return axios.get(API_URL + `students/settings`, { headers: authHeader() });
      }
      admin(){
          return axios.get(API_URL + `settings`, { headers: authHeader() });

      }

}



export default new SettingApi();
