<template>
<!-- <div v-if="isOpen"> -->
<section class="z-20 h-screen w-screen bg-gray-500 fixed top-0 opacity-50 right-0" @click="$emit('close-modal')"></section>
<div class="absolute inset-0">
    <div class="flex h-full mt-[3.75rem] rounded-sm">
        <div class=" z-30 m-auto bg-white border rounded p-2 shadow md:w-1/3 w-4/5  modal-content">
            <div class="p-3">
                <h1 class="text-2xl text-center font-medium">Change Password</h1>
                <form @click="prevent" class="my-2 p-3">
                    <div class="my-2">
                        <label>Old Password</label>
                        <input v-model="old_password" class="rounded w-full p-2 border-2 border-blue-200 focus:ring-blue-500 focus:border-blue-500" type="password" placeholder="Old Password">
                    </div>
                    <div class="my-2">
                        <label>password</label>
                        <input v-model="password" class="rounded w-full p-2 border-2 border-blue-200 focus:ring-blue-500 focus:border-blue-500" type="password" placeholder="password">
                    </div>
                    <div class="my-3">
                        <label>password confirmation</label>
                        <input v-model="password_confirmation" class="rounded w-full p-2 border-2 border-blue-200 focus:ring-blue-500 focus:border-blue-500" type="password" placeholder="password confirmation">
                    </div>
                    <div class=" flex justify-end space-x-4">
                        <button type="button" class="px-4 py-2 bg-indigo-500 hover:bg-indigo-600  shadow-indigo-500/50 text-white rounded-md" @click="updatepassword()">Save</button>
                        <button @click="$emit('close-modal')" type="button" class="px-3 py-2 bg-gray-300 hover:bg-gray-400 rounded-md shadow-gray-300 text-black">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->
</template>



<script>
import changepasswordApi from '@/utils/changepasswordApi';
export default {
    props: {
        showmodal2: Boolean,
    },
    data() {
        return {
            old_password: '',
            password: '',
            password_confirmation: '',
        }
    },
    methods: {
        updatepassword() {
            let params = {
                old_password: this.old_password,
                password: this.password,
                password_confirmation: this.password_confirmation,
            }
            changepasswordApi.post(params)
                .then((res) => {
                    console.log(res);
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        icon: 'success',
                        title: 'Password changed successfully!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    this.$emit('close-modal')
                }).catch(error => {
                    this.$swal({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        icon: 'error',
                        title: '',
                        text: error.response.data.message,
                    });
                })

            // this.$emit('close-modal');
        },

    },
}
</script>

<style>
      .modal-content {
  animation-name: animatetop;
  animation-duration:1000ms
}

/* Add Animation */
@keyframes animatetop {
  from {top:-300px; opacity:0}
  to {top:0; opacity:1}
}
</style>
