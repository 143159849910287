<template>
  <div>
    <!-- <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75"/>
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col  bg-slate-50 pt-2 pb-4">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                  </button>
                </div>
              </TransitionChild>
              <router-link to="/">
                <div class="flex gap-3 h-16 flex-shrink-0 items-center bg-slate-50 px-3 shadow-xl">
                  <img class="object-cover h-14 w-14" src="https://octalinfotech.in/wp-content/uploads/2023/12/cropped-OCTAL-1-1.png" alt="Your Company"/>
                  <h1 class="text-black text-lg font-bold space-x-3">OCTAL INFOTECH</h1>
                </div>
              </router-link>
              <div class="flex flex-1 flex-col overflow-y-auto">
                <nav class="flex-1 space-y-1 px-2 py-4">
                  <template v-for="item in navigationItems" :key="item.name">
                    <router-link v-if="item.name" :to="item.href ? item.href : '/'" :class="[item.current ? 'bg-gray-900 text-white' : 'text-black  hover:bg-gray-200 hover:text-black  text-decoration-line:none ', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md no-underline']" style="text-decoration: none;">
                      <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-gray-400', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true"/>
                      {{ item.name }}
                    </router-link>
                  </template>
</nav>
</div>
</DialogPanel>
</TransitionChild>
<div class="w-14 flex-shrink-0" aria-hidden="true">
  Dummy element to force sidebar to shrink to fit close icon
</div>
</div>
</Dialog>
</TransitionRoot> -->

    <!-- Static sidebar for desktop -->
    <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex min-h-0 flex-1 flex-col  ">
        <!-- <router-link to="/" class="hover:no-underline">
          <div class="flex gap-3 h-16 flex-shrink-0 items-center bg-slate-50 px-3 shadow-sm">
            <img class="object-cover h-14 w-14" src="https://octalinfotech.in/wp-content/uploads/2023/12/cropped-OCTAL-1-1.png" alt="Your Company"/>
            <h1 class="text-black text-lg font-bold space-x-3">OCTAL INFOTECH</h1>
          </div>
        </router-link> -->
        <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col z-10 ml-3">
          <div class="flex min-h-[90%]  flex-col my-auto shadow-xl bg-white rounded-2xl p-2">
            <router-link to="/" class="hover:no-underline">
              <div class="flex gap-3 justify-center items-center rounded-2xl h-16 flex-shrink-0  bg-white ">
                <img class="object-cover h-14 w-14" src="https://ims-api-dev.octalinfotech.com/storage/logo_images_/1714472300.png" alt="Your Company" />
                <h1 class="text-black text-lg font-bold space-x-3">Octal Infotech</h1>
              </div>
              <hr class="text-gray-50 w-40 mx-auto " />
            </router-link>
            <div class="flex flex-1 flex-col overflow-y-auto">
              <nav class="flex h-full flex-col justify-between space-y-3 px-2 py-4">
                <div>
                  <template v-for="(item, i) in navigationItems" :key="item.name">
                    <div v-if="item.name && item.subMenu && item.subMenu.length > 0"
                      class="flex flex-col duration-500 relative py-[6px]">
                      <div class="flex justify-between m-2 duration-1000 relative" @click="manageSubMenu(i)"
                        :class="!isAllow(item) ? 'cursor-not-allowed' : 'cursor-pointer'">
                        <div class="flex space-x-3">
                          <component :is="item.icon"
                            :class="[item.current ? '' : '', ' flex-shrink-0 h-6 w-6', item.color ]"
                            aria-hidden="true" />
                          <h3 class="flex justify-center items-center">{{ item.name }}</h3>
                        </div>
                        <span v-if="item.subMenu && item.subMenu.length > 0">
                          <ChevronDownIcon class="h-4 material-icons fill-slate-500" aria-hidden="true" />
                        </span>
                      </div>
                      <transition name="slide-fade">
                        <div v-if="subMenuHandle[i]" class=" h-full rounded-lg">
                          <div v-for="(subMenu, subMenuIndex) in item.subMenu" :key="subMenu.name"
                            @click="handleSelectMenu(i, subMenuIndex)">
                            <router-link v-if="subMenu" :to="subMenu.href ? subMenu.href : '/'"
                              class="keyFrame mb-2 ml-3  button flex rounded-sm p-2 hover:shadow-xl hover:scale-[1.01] transition-all hover:bg-white hover:no-underline space-x-3">
                              <component :is="subMenu.icon"
                                :class="[subMenu.current ? '!text-white' : subMenu.color, 'flex-shrink-0 h-6 w-6 ']"
                                aria-hidden="true" />
                              <h3 class="flex justify-center items-center">{{ subMenu.name }}</h3>
                            </router-link>
                          </div>
                        </div>
                      </transition>
                    </div>
                    <router-link v-else-if="item.name" :to="item.href ? item.href : '/'" @click="item.current = true"
                      :class="[item.current ? '' : '', 'group flex items-center p-2 py-2  text-[14px] font-medium rounded-md no-underline space-x-4']"
                      style="text-decoration: none;">
                      <component :is="item.icon"
                        :class="[item.current ? 'text-white!' : item.color, 'flex-shrink-0 h-6 w-6  ',]"
                        aria-hidden="true" />
                      <h3 :class="[item.current ? ' ' : '']">
                        {{ item.name }} </h3>
                    </router-link>
                  </template>
                </div>
                <div>
                  <RouterLink to="/" class="text-decoration-none ">
                    <div class="flex justify-center space-x-2  w-full px-8 py-2 font-bold leading-normal text-center text-white align-middle 
              transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md select-none bg-150 bg-x-25 text-xs
               hover:shadow-xs hover:-translate-y-px">
                      <button @click="showSmg">
                        Logout
                      </button>
                      <ArrowUturnLeftIcon class="w-5 h-5" />
                    </div>
                  </RouterLink>
                </div>
              </nav>
            </div>
          </div>

        </div>
        <!-- <div class="flex flex-1 flex-col overflow-y-auto">
          <nav class="flex-1 space-y-1 px-2 py-4">
            <template v-for="item in navigationItems" :key="item.name">
              <router-link v-if="item.name" :to="item.href ? item.href : '/'" :class="[item.current ? 'bg-gray-900 text-white' : 'text-black  hover:bg-blue-100 hover:text-black text-decoration-line:none ', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md no-underline']" style="text-decoration: none;">

                <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-gray-400', 'mr-3 flex-shrink-0 h-6 w-6',item.color ]" aria-hidden="true"/>
                {{ item.name }}
              </router-link>
            </template>

          </nav>
        </div> -->
      </div>
    </div>
    <div class="flex flex-col md:pl-64">
      <!-- <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 shadow-sm">
        <button type="button" class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true"/>
        </button>
        <div class="flex items-center font-semibold bg-slate-50">
          <span class="pl-3">{{ $route.meta.title }}</span>
        </div>
        <div class="flex flex-1 justify-end items-center px-4 bg-slate-50" v-show="$route.meta.title">
          <div class="justify-center md:flex hidden w-full font-semibold  md:text-xl lg:text-2xl">Institute Management
            System
          </div>
          <div class="ml-4 flex items-center md:ml-6">
            Profile dropdown
            <Menu as="div" class="relative ml-3">
              <div>

                <MenuButton class="flex max-w-xs items-center justify-between rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full" :src="profile" alt=""/>
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                    <a href="javascript:void(0)" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 font-bold']" @click="showmodal = true">Profile
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="javascript:void(0)" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 font-bold']" @click="showmodal2 = true">Change
                      Password
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="#" :class="[active ? 'bg-gray-100' : '', ' px-4 py-2  text-sm  text-gray-700 flex gap-4 items-center hover:no-underline font-bold']" @click="handleNavigation($event, 'logout')">
                      Logout</a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        {{ showmodal }}
        <profile v-if="showmodal" @close-modal="onProfileModalClose" showmodal="showmodal"/>
        <ChangePassword v-if="showmodal2" @close-modal="showmodal2 = false" showmodal="showmodal2"/>
      </div> -->
      <main v-if="rednerComponent">
        <div :key="updateKey" class="mx-auto md:max-w-full px-10 sm:px-6 md:px-8 z-10 relative pt-8">
          <slot name="main"></slot>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import profileApi from '@/utils/profileApi';
import SettingApi from '@/utils/SettingApi';
import router from '@/router';
import profile from '../Auth/studentsProfile.vue';
import ChangePassword from '../Auth/studentChangePassword.vue';
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  Bars3BottomLeftIcon,
  HomeIcon,
  XMarkIcon,
  ArrowUturnLeftIcon,
  DocumentIcon,
  PowerIcon,
  HandThumbUpIcon,
  UserPlusIcon,
  DocumentPlusIcon,
  DocumentCheckIcon,
  AcademicCapIcon,
  UserCircleIcon,
  UserGroupIcon,
  PlusCircleIcon,

} from '@heroicons/vue/24/outline';

export default {

  components: {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    UserCircleIcon,
    TransitionChild,
    TransitionRoot,
    Bars3BottomLeftIcon,
    UserGroupIcon,
    profile,
    ChangePassword,
    ArrowUturnLeftIcon,
    XMarkIcon,
    PowerIcon,
    DocumentPlusIcon,
    DocumentCheckIcon, AcademicCapIcon,
    HandThumbUpIcon,
  },

  data() {
    return {
      userNavigation: [
        {
          name: 'Your Profile',
          href: '#',
        },
        {
          name: 'Settings',
          href: '#',
        },
        {
          name: 'logout',
          href: '/login',
        },
      ],
      profile: '',
      company_Name: '',
      company_Logo: '',
      sidebarOpen: false,
      showmodal: false,
      showmodal2: false,
      updateKey: 0,
      rednerComponent: true,
    };
  },

  computed: {
    navigationItems() {
      return [
        {
          name: 'Dashboard',
          color: 'text-yellow-500',
          href: '/student/dashboard',
          icon: HomeIcon, current: false,
        },

        {
          name: 'TodoList',
          color: 'text-indigo-500',
          href: '/student/todos',
          icon: PlusCircleIcon
          , current: false,
        },

        {
          name: 'Today Task',
          color: 'text-blue-500',
          href: '/student/today-task',
          icon: AcademicCapIcon
          , current: false,
        },

        {
          name: 'Completed Tasks',
          color: 'text-red-500',
          href: '/student/complete-tasks',
          icon: HandThumbUpIcon
          , current: false,
        },

        {
          name: 'Attendances',
          color: 'text-orange-500',
          href: '/student/attendances',
          icon: DocumentCheckIcon
          , current: false,
        },

        {
          name: 'Leaves',
          color: 'text-pink-500',
          href: '/student/leaves',
          icon: UserGroupIcon
          , current: false,
        },

        {
          name: 'Completed Courses',
          color: 'text-lime-500',
          href: '/student/completed-courses',
          icon: HandThumbUpIcon
          , current: false,
        },

        {
          name: 'Holidays',
          color: 'text-indigo-500',
          href: '/student/holidays',
          icon: HandThumbUpIcon
          , current: false,
        },
        { name: 'Profile',
          href: '/student/profile',
          color: 'text-pink-500',
          icon: UserCircleIcon,
          current: false 
        },

      ];
    },
  },
  mounted() {
    // this.getSettings();
  },
  methods: {
    getSettings() {
      SettingApi.index().then((res) => {
        console.error(res)
        this.company_Name = res.data.data.company_name;
        this.company_Logo = res.data.data.company_logo;

      });
    },
    handleNavigation(event, name) {
      if (name === 'logout') {
        event.preventDefault();
        localStorage.clear();
        router.push('/student/login');
      }
    },

    async onProfileModalClose() {
      this.showmodal = false;
      this.getProfile();
      this.$router.go();
    },

    getProfile() {
      profileApi.studentProfile().then(response => {
        
        
        this.profile = response.data.data.file_url;
      }).catch(error => {
        console.log(error);
      });
    },

  },
  created() {
    this.getProfile();
    
  },
  watch: {
    '$route.params.getSettings': function (getSettings) {
      console.log(getSettings);
      this.getSettings();
    },
  },

};
</script>

<style scoped>
.router-link-active {
  /* background:repeating-linear-gradient(45deg, black, transparent 100px) !important; */
  background-color: #007BFF !important;
  color: white !important;

}
</style>
