<template>
  <div class="relative">
    <div v-show="!authRoute" class="absolute top-0 min-h-[30%] w-full bg-[#5E72E4] " style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg'); background-position-y: 50%; position:fixed; ">
      <span class="imgopasity"></span></div>
    <router-view v-if="authRoute"/>
    <Layout v-else-if="status">
      <template #main>
        <router-view/>
      </template>
    </Layout>

    <StudentLayout v-else>
      <template #main>
        <router-view/>
      </template>
    </StudentLayout>
  </div>
</template>


<script>
import Layout from './components/Layout.vue';
import StudentLayout from './components/StudentLayout.vue';

export default {
  components: {
    Layout, StudentLayout,
  },
  data () {
    return {
      authRoute: true,
      name: true,
      status: true,
    };
  },
  watch: {
    $route: {
      handler: function (path) {
        this.authRoute = (path.name === 'login' || path.name === 'register' || path.name === 'Studentlogin' ||
            path.name === 'Layouts' || path.name === 'Contact' || path.name === 'Courses' || path.name == 'Enquiry' ||
            path.name == 'AboutUsPage' || path.name == 'PlacementPage');
        let token = localStorage.getItem('token');
        if (this.$store.state.user.type) {
          this.status = this.$store.state.user.type === 'user';
        }
        if (!token && path.name !== 'Studentlogin' && path.name !== 'Layouts' && path.name !== 'Contact' &&
            path.name !== 'Courses' && path.name !== 'Enquiry' && path.name !== 'AboutUsPage' && path.name !==
            'PlacementPage') {
          this.$router.push('/admin/login');
        }
      },
    },
  },
  mounted () {
    this.$store.commit('initialiseStore');
  },

};
</script>


<style>
@import "@vueform/multiselect/themes/default.css";

.imgopasity {
  background-color: #5E72E4;
  position: absolute;
  background-position: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.7;

}
</style>
