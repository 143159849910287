import axios from 'axios';
import authHeader from './setAuthHeader';
let API_URL = process.env.VUE_APP_ROOT_API

class profileApi {

    create(data) {
        return axios.post(API_URL + 'users', data , { headers: authHeader() });
    }

    all() {
      return axios.get(API_URL + 'users', { headers: authHeader() });
    }

    delete(id) {
        return axios.delete(API_URL + `users/${id}`+"/delete", { headers: authHeader() });
    }
    update(data) {
      return axios.post(API_URL + 'profile/update', data, { headers: authHeader() });
  }
  get(){
    return axios.get(API_URL + 'profile/show', { headers: authHeader() });
  }

  
  studentProfile(){
    return axios.get(API_URL + 'student/profile', { headers: authHeader() });
  }

  studentProfileUpdate(data){
    // data['_method'] = 'PUT';
    return axios.put(API_URL + 'student/profile-update',data, { headers: authHeader() });
  }

  forget(data){
    return axios.post(API_URL + 'forgot-password',data, { headers: authHeader() });
  }
  StudentsForget(data){
    return axios.post(API_URL + 'student/forgot-password',data, { headers: authHeader() });
  }

  
  }
  
  export default new profileApi();