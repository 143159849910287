<template>
  <div :key="keyChanged">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40 md:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
          enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
          leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 z-40 flex">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full" enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
            leave-to="-translate-x-full">
            <DialogPanel class="relative flex w-full max-w-xs flex-1 flex-col  bg-slate-50 pt-2 pb-4">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute top-0 right-0 -mr-12 pt-2">
                  <button type="button"
                    class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    @click="sidebarOpen = false">
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div class="flex gap-3 h-16 flex-shrink-0 items-center bg-slate-50 px-3 shadow-xl">
                <img class="object-cover h-14 w-14" src="https://octalinfotech.in/wp-content/uploads/2023/12/cropped-OCTAL-1-1.png" alt="Your Company "/>
                <h1 class="text-black text-lg font-bold space-x-3">OCTAL INFOTECH</h1>
                <img class="object-cover h-14 w-14"
                  src="https://octalinfotech.in/wp-content/uploads/2023/12/cropped-OCTAL-1-1.png" alt="Your Company" />
                <h1 class="text-black text-lg font-bold space-x-3">OCTAL INFOTECH </h1>
              </div>
              <div class="flex flex-1 flex-col overflow-y-auto">
                <nav class="flex-1 space-y-1 px-2 py-4">
                  <template v-for="item in navigationItems" :key="item.name">
                    <router-link v-if="item.name" :to="item.href ? item.href : '/'"
                      :class="[item.current ? 'bg-gray-900 text-white' : 'text-black  hover:bg-gray-200 hover:text-black focus:bg-gray-600 text-decoration-line:none active:bg-slate-600 ', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md no-underline']"
                      style="text-decoration: none;">
                      <component :is="item.icon"
                        :class="[item.current ? 'text-gray-300' : 'text-gray-400', 'mr-3 flex-shrink-0 h-6 w-6']"
                        aria-hidden="true" />
                      {{ item.name }}
                    </router-link>
                  </template>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
          <div class="w-14 flex-shrink-0" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col z-10 ml-3">
      <div class="flex min-h-[90%]  flex-col my-auto shadow-2xl bg-white rounded-2xl p-2">
        <router-link to="/" class="hover:no-underline">
          <div class="flex gap-3 justify-center items-center rounded-2xl h-16 flex-shrink-0  bg-white ">
            <img class="object-cover h-14 w-14" :src="this.company_Logo" alt="Your Company" />
            <h1 class="text-black text-lg font-bold space-x-3">{{ this.company_Name }}</h1>
          </div>
          <hr class="text-gray-50 w-40 mx-auto " />
        </router-link>
        <div class="flex flex-1 flex-col overflow-y-auto">
          <nav class="flex h-full flex-col justify-between space-y-3 px-2 py-4">
            <div>
              <template v-for="(item, i) in navigationItems" :key="item.name">
                <div v-if="item.name && item.subMenu && item.subMenu.length > 0"
                  class="flex flex-col duration-500 relative py-[6px]">
                  <div class="flex justify-between m-2 duration-1000 relative" @click="manageSubMenu(i)"
                    :class="!isAllow(item) ? 'cursor-not-allowed' : 'cursor-pointer'">
                    <div class="flex space-x-3">
                      <component :is="item.icon" :class=" [ item.current ? '' : '' , 'flex-shrink-0 h-6 w-6' , item.color ]"  />
                      <h3 class="flex justify-center items-center">{{ item.name }}</h3>
                    </div>
                    <span v-if="item.subMenu && item.subMenu.length > 0">
                      <ChevronDownIcon class="h-4 material-icons fill-slate-500" aria-hidden="true" />
                    </span>
                  </div>
                  <transition name="slide-fade">
                    <div v-if="subMenuHandle[i]" class=" h-full rounded-lg">
                      <div v-for="(subMenu, subMenuIndex) in item.subMenu" :key="subMenu.name"
                        @click="handleSelectMenu(i, subMenuIndex)">
                        <router-link v-if="subMenu" :to="subMenu.href ? subMenu.href : '/'"
                          class="keyFrame mb-2 ml-3  button flex rounded-sm p-2 hover:shadow-xl hover:scale-[1.01] transition-all hover:bg-white hover:no-underline space-x-3">
                          <component :is="subMenu.icon"
                            :class="[subMenu.current ? '!text-white' : subMenu.color, 'flex-shrink-0 h-6 w-6 ']"
                            aria-hidden="true" :data-current="subMenu.current"/>
                          <h3 class="flex justify-center items-center" :data-current="subMenu.current">{{ subMenu.name }}</h3>
                        </router-link>
                      </div>
                    </div>
                  </transition>
                </div>
                <router-link v-else-if="item.name" :to="item.href ? item.href : '/'" @click="item.current = true"
                  :class="[item.current ? '' : '', 'group flex items-center p-2 text-sm font-medium rounded-md no-underline space-x-3']"
                  style="text-decoration: none;">
                  <component :is="item.icon"
                    :class="[item.current ? 'text-white!' : item.color, 'flex-shrink-0 h-6 w-6  ',]"
                    aria-hidden="true" />
                  <h3 :class="[item.current ? ' ' : '']">
                    {{ item.name }} </h3>
                </router-link>
              </template>
            </div>
            <div>
              <RouterLink to="/" class="text-decoration-none ">
                <div class="flex justify-center space-x-2  w-full px-8 py-2 font-bold leading-normal text-center text-white align-middle
              transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md select-none bg-150 bg-x-25 text-xs
               hover:shadow-xs hover:-translate-y-px">
                  <button @click="showSmg">
                    Logout
                  </button>
                  <ArrowUturnLeftIcon class="w-5 h-5" />
                </div>
              </RouterLink>
            </div>
          </nav>
        </div>
      </div>

    </div>
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex flex-col md:pl-64">
      <!-- <div class="flex justify-between items-center p-3 z-10 max-w-[95rem] mx-auto w-full"> -->
        <!-- <div>
          <p></p>
          <p class="text-white font-bold">{{ $route?.meta?.title }}</p>
        </div> -->
        <div>
          <!-- <div class="ml-4 flex items-center md:ml-6"> -->

            <Menu as="div" class="relative ml-3">
              <!-- <div>
                <MenuButton
                  class="flex max-w-xs items-center justify-between rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full" :src="profile" alt="" />
                </MenuButton>
              </div> -->
              <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                  <a href="javascript:void(0)"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 font-bold']"
                    @click="showmodal = true">Profile
                  </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                  <a href="javascript:void(0)"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 font-bold']"
                    @click="showmodal2 = true">Change
                    Password
                  </a>
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                  <a href="/" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 font-bold']"
                    @click="handleNavigation($event, 'Logout')">Logout
                  </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          <!-- </div> -->
        </div>
      <!-- </div> -->
      <!-- <div class="sticky top-0 z-10 flex h-16 flex-shrink-0 bh-white shadow-sm"> -->
        <!-- <button type="button"
          class="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex items-center font-semibold bg-slate-50">
          <span class="pl-3">{{ $route.meta.title }}</span>
        </div> -->
        <!-- <div class="flex flex-1 justify-end items-center px-4 bg-slate-50" v-show="$route.meta.title">
          <div class="justify-center md:flex hidden w-full font-semibold  md:text-xl lg:text-2xl">Institute Management
            System
          </div>
          <div class="ml-4 flex items-center md:ml-6">
            &lt;!&ndash; Profile dropdown &ndash;&gt;
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton
                  class="flex max-w-xs items-center justify-between rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span class="sr-only">Open user menu</span>
                  <img class="h-8 w-8 rounded-full" :src="profile" alt="" />
                </MenuButton>
              </div>
              <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <MenuItem v-slot="{ active }">
                  <a href="javascript:void(0)"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 font-bold']"
                    @click="showmodal = true">Profile
                  </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                  <a href="javascript:void(0)"
                    :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 font-bold']"
                    @click="showmodal2 = true">Change
                    Password
                  </a>
                  </MenuItem>

                  <MenuItem v-slot="{ active }">
                  <a href="/" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 font-bold']"
                    @click="handleNavigation($event, 'logout')">Logout
                  </a>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div> -->
        <profile v-if="showmodal" @close-modal="onProfileModalClose" showmodal="showmodal" />
        <ChangePassword v-if="showmodal2" @close-modal="showmodal2 = false" showmodal="showmodal2" />
      <!-- </div> -->
      <main class="flex-1 my-auto " v-if="rednerComponent">
        <div :key="updateKey">
          <slot name="main" class="z-[1]"></slot>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import profileApi from '@/utils/profileApi';
import SettingApi from '@/utils/SettingApi';
import router from '@/router';
import profile from '../Auth/profile.vue';
import ChangePassword from '../Auth/changepassword.vue';
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import {
  Bars3BottomLeftIcon,
  Cog6ToothIcon,
  TrashIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  AcademicCapIcon,
  UserPlusIcon,
  CurrencyRupeeIcon,
  UserGroupIcon,
  QuestionMarkCircleIcon,
  CubeIcon,
  UserCircleIcon,
  TvIcon,
  HandRaisedIcon,
  UserIcon,
  DocumentIcon,
  HandThumbUpIcon,
  BellAlertIcon,
  BellIcon,
  ClipboardDocumentListIcon,
  DocumentCheckIcon,
  ComputerDesktopIcon,
  PlusIcon,
  PlusCircleIcon,
  ChevronDownIcon,
  ArrowUturnLeftIcon,

} from '@heroicons/vue/24/outline';
import toast from 'izitoast';
export default {

  components: {
    Dialog,
    DialogPanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    Bars3BottomLeftIcon,
    profile,
    ChangePassword,
    XMarkIcon,
    DocumentCheckIcon,
    ComputerDesktopIcon,
    PlusIcon,
    PlusCircleIcon,
    ChevronDownIcon,
    ArrowUturnLeftIcon,

    // BanknotesIcon

  },

  data() {

    return {
      userNavigation: [
        {
          name: 'Your Profile',
          href: '#',
        },
        {
          name: 'Settings',
          href: '#',
        },
        {
          name: 'Logout',
          href: '/admin/login',
        },
      ],
      company: {},
      company_Name: '',
      company_Logo: '',
      profile: '',
      sidebarOpen: false,
      showmodal: false,
      showmodal2: false,
      updateKey: 0,
      rednerComponent: true,
      isExpanded: null,
      subMenuHandle: null,
      keyChanged: true,

    };
  },

  created() {
    this.getProfile();
    this.isExpanded = (localStorage.getItem('isExpanded') === 'true');
    this.subMenuHandle = (new Array(this.navigationItems.length).fill(false));
  },

  // let route = useRoute();

  //  watch(route, (val) => {
  //  getSetting();
  // });

  // watch(){
  //     getSettings(val);
  // },
  watch: {
    '$route.params.getSettings': function (getSettings) {
      console.log(getSettings);
      this.getSettings();
    },
  },
  mounted() {
    this.getSettings();
  },

  methods: {
    getSettings() {
      SettingApi.admin().then((res) => {
        console.log(res);
        this.company_Name = res.data.data.company_name;
        this.company_Logo = res.data.data.company_logo;
      })
      .catch(err=>{console.log(err,454);})
    },
    showSmg() {
      toast.success({
        title: 'Logout',
        message: 'User Logout successfully',
        position: 'topRight'
      })
    },
    handleNavigation(event, name) {
      if (name === 'Logout') {
        event.preventDefault();
        localStorage.clear();
        router.push('/');
      }
    },
    isAllow(item) {
      return item.name && item.subMenu && item.subMenu.filter(element => element !== '').length > 0;
    },
    async onProfileModalClose() {

      this.showmodal = false;
      this.$router.go();
    },
    getProfile() {
      profileApi.get().then(response => {
        this.profile = response.data.data.file;
      }).catch(error => {
        console.log(error);
      });
    },

    handleSelectMenu(mainIndex, subIndex) {
      this.navigationItems[mainIndex].subMenu.forEach((res, index) => {
        console.log(index, subIndex);
        if (index === subIndex) {
          res.current = true;
        } else {
          res.current = false;
        }
      });

      this.keyChanged = !this.keyChanged;

    },
    manageSubMenu(i) {
      this.subMenuHandle[i] ? this.subMenuHandle[i] = false
        : (this.subMenuHandle = new Array(this.navigationItems.length).fill(false),
          this.subMenuHandle[i] = !this.subMenuHandle[i]);
    },

  },

  computed: {

    navigationItems() {
      return [
        { name: 'Dashboard', href: '/admin/dashboard', color: 'text-yellow-500', icon: HomeIcon, current: false },

        {
          name: 'Students', color: 'text-blue-500', href: '/admin/students', icon: UserCircleIcon, current: false,
          subMenu: [
            this.$store.state.permissions.includes('students_module') ? {
              name: 'Students',
              color: 'text-blue-500',
              href: '/admin/students',
              icon: AcademicCapIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('todo_module') ? {
              name: 'Todos',
              href: '/admin/todos',
              color: 'text-red-400',
              icon: PlusCircleIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('attendances_module') ? {
              name: 'Attendances',
              href: '/admin/attendances',
              color: 'text-red-500',
              icon: HandRaisedIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('leave_module') ? {
              name: 'Leaves',
              href: '/admin/leaves',
              color: 'text-orange-500',
              icon: DocumentCheckIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('packages_module') ? {
              name: 'Packages',
              href: '/admin/packages',
              color: 'text-violet-500',
              icon: CubeIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('studentPackages_module') ? {
              name: 'Student Packages',
              href: '/admin/student-packages',
              color: 'text-pink-500',
              icon: UsersIcon,
              current: false,
            } : '',

          ],
        },

        {
          name: 'Tasks', color: 'text-orange-500', href: '/admin/tasks', icon: DocumentIcon, current: false,
          subMenu: [
            this.$store.state.permissions.includes('task_module') ? {
              name: 'Tasks',
              href: '/admin/tasks',
              color: 'text-green-500',
              icon: DocumentIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('courses_module') ? {
              name: 'Courses',
              href: '/admin/courses',
              color: 'text-purple-500',
              icon: TvIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('task_course_module') ? {
              name: 'Task Courses',
              href: '/admin/task-courses',
              color: 'text-cyan-500',
              icon: ClipboardDocumentListIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('completed_task_module') ? {
              name: 'Completed Tasks',
              href: '/admin/complete-tasks',
              color: 'text-pink-500',
              icon: HandThumbUpIcon,
              current: false,
            } : '',
          ],
        },

        {
          name: 'Notifications',
          color: 'text-purple-500',
          href: '/admin/notifications',
          icon: BellAlertIcon,
          current: false,
          subMenu: [
            this.$store.state.permissions.includes('notification_module') ? {
              name: 'Notifications',
              href: '/admin/notifications',
              color: 'text-lime-500',
              icon: BellAlertIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('holiday_module') ? {
              name: 'Holidays',
              href: '/admin/holidays',
              color: 'text-gray-500',
              icon: BellIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('enquiries_module') ? {
              name: 'Inquiries',
              href: '/admin/inquirys',
              color: 'text-indigo-500',
              icon: QuestionMarkCircleIcon,
              current: false,
            } : '',
          ],
        },

        {
          name: 'Users', color: 'text-green-600', href: '/admin/settings', icon: UserPlusIcon, current: false,
          subMenu: [
            this.$store.state.permissions.includes('users_module') ? {
              name: 'Users',
              href: '/admin/users',
              color: 'text-pink-500',
              icon: UserGroupIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('teachers_module') ? {
              name: 'Teachers',
              href: '/admin/teachers',
              color: 'text-yellow-500',
              icon: UserCircleIcon,
              current: false,
            } : '',
          ],
        },

        {
          name: 'Settings', color: 'text-red-500', href: '/admin/settings', icon: Cog6ToothIcon, current: false,
          subMenu: [
            this.$store.state.permissions.includes('roles_module') ? {
              name: 'Settings',
              href: '/admin/settings',
              color: 'text-lime-500',
              icon: Cog6ToothIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('roles_module') ? {
              name: 'Truncate',
              href: '/admin/settings-menu',
              color: 'text-red-500',
              icon: TrashIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('roles_module') ? {
              name: 'Role Permissions',
              href: '/admin/roles',
              color: 'text-purple-700',
              icon: UserIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('company_module') ? {
              name: 'Companies',
              href: '/admin/company',
              color: 'text-green-800',
              icon: ComputerDesktopIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('payments_module') ? {
              name: 'Payments',
              href: '/admin/payments',
              color: 'text-red-500',
              icon: CurrencyRupeeIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('contactus_module') ? {
              name: 'ContactUs',
              href: '/admin/contact-us',
              color: 'text-green-500',
              icon: UserIcon,
              current: false,
            } : '',
            this.$store.state.permissions.includes('roles_module') ? {
              name: 'Placements',
              href: '/admin/placement',
              icon: UserIcon,
              current: false,
            } : {},

          ],
        },
        { name: 'Profile', href: '/admin/profile', color: 'text-pink-500', icon: UserCircleIcon, current: false },
      ];
    },
  },
};
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  /*height: 100%;*/
  /*transform: translateZ(100%);*/
  opacity: 0;
}

.router-link-active {
  background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(97, 186, 255, 1) 0%, rgba(166, 239, 253, 1) 90.1%);
  font-weight: bold !important;
  color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.keyFrame {
  position: relative;
  animation: mylove 5s;
}

@keyframes mylove {
  from {
    top: 0px;
  }

  to {
    transition-duration: 5s;
  }
}
</style>
